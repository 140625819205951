import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import ProTip from '../components/ProTip';
import Copyright from '../components/Copyright';
import Header from '../components/header';
import Content from '../components/Content';
import PageIntro from '../components/pageIntro';
import PageTitle from '../components/pageTitle';
import Card4 from '../components/Card4';
import Card3 from '../components/Card3';




export default function About() {
  return (
    <Grid Container direction="column">
      <Grid item>
        <Header title="Produit"/>
      </Grid>
      <Grid item>
      <Container maxWidth="sm">
      <Box my={4}>
      <PageTitle/>
      <PageIntro content="Ma sensibilité produit, exarcerbée par mon parcours entrepreneurial, c'est savoir qu'in fine, les utilisateurs sont seuls juges, même en interne " />
      <Card3 title="Datapp plateforme logistique Saas" customer="" description="Grâce à Datapp je sais combien la réalité du terrain est exigente et seule juge, et connais les nombreuses différentes une application qui fonctionne et un produit vendu en SaaS" moreLink="https://datapp.fr/site/" />
<Card3 title="Appsociétaire plateforme B2B" customer="" description="Appsociétaire témoigne de tous les la nécessité de packager comme un produit un projet informatique pour qu'il transforme réellement le quotidien de travail en entreprise" moreLink="https://www.southside-interactive.com/application-societaire/" />
<Card3 title="Leguichet.fr e-commerce" customer="" description="Après plus de 7 ans d'activité et des dizaines de milliers d'utilisateurs, j'ai une idée claire de ce qu'implique le e-commerce, sa gestion quotidienne et les enjeux associés" moreLink="http://blog.leguichet.fr/" />
<Card4 title="Nomoney app paiement B2C " customer="" description="Cette application de paiement sans argent lancée fin 2011 m'a permis de rentrer dans l'écosystème startup : incubateurs, hackathon, meetups, circuits de financements…" moreLink="" />
<Card3 title="L'arrière-boutique" customer="" description="Cette plateforme destinée à inciter les consommateurs à revendre d'occasion fut l'occasion de travailler avec une SSII pionnière du cloud, Zenexity devenue Zengularity, désormais intégrée au groupe Fabernovel" moreLink="https://zengularity.com/fr" />
<Card4 title="Flyers" customer="" description="Les flyers sont un exemple simple et concret des à côtés d'un projet informatiques qui sont parfois nécessaires pour déployer avec succès un outil qui sera réellement utilisé" moreLink="" />
<Card4 title="Roadmap de fin d'étape" customer="" description="La logique de 'Roadmap' qui témoigne d'un périmètre en constante évolution, de la capacité de ce périmètre à prendre en compte les derniers constats effectués sur l'existant, sont une des bases de la vision produit dont le développement d'un outil peut très grandemenent bénéficier" moreLink="" />
<Card4 title="Jira Service Desk" customer="" description="Les relations avec les clients utilisateurs d'un produit nécessitent la mise en place d'outils de gestion de tickets que là aussi, la mise en place d'un outil a vocation a intégrer" moreLink="" />
<Card4 title="Notification relances" customer="" description="Si les notifications basées sur l'utilisation sont fréquentes dans les nombreux produits que nous utilisons sur nos téléphones au quotidien, leur usage systématique dans les outils de travail est tout aussi pertinent" moreLink="" />
<Card4 title="Zendesk" customer="" description="Outre la gestion de ticket, la mise en place d'un helpdesk de type Zendesk est un investissement pertinent dans la mise en place d'un outil à usage interne dans de nombreux contextes" moreLink="" />
      </Box>
      </Container>
      </Grid>
    </Grid>
  );
}
